@charset "UTF-8";
/* Klavika Web Basic Light */
@font-face {
	font-family: 'KlavikaWebBasic';
	src: url('../fonts/KlavikaWebBasicLight/KlavikaWebBasicLight.eot');
	src: url('../fonts/KlavikaWebBasicLight/KlavikaWebBasicLight.eot?#iefix') format('embedded-opentype'),
         url('../fonts/KlavikaWebBasicLight/KlavikaWebBasicLight.woff2') format('woff2'),
         url('../fonts/KlavikaWebBasicLight/KlavikaWebBasicLight.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	}

/* Klavika Web Basic Light Italic */
	@font-face {
	font-family: 'KlavikaWebBasic';
	src: url('../fonts/KlavikaWebBasicLightItalic/KlavikaWebBasicLightItalic.eot');
	src: url('../fonts/KlavikaWebBasicLightItalic/KlavikaWebBasicLightItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/KlavikaWebBasicLightItalic/KlavikaWebBasicLightItalic.woff2') format('woff2'),
         url('../fonts/KlavikaWebBasicLightItalic/KlavikaWebBasicLightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	}

/* Klavika Web Basic Regular */
	@font-face {
	font-family: 'KlavikaWebBasic';
	src: url('../fonts/KlavikaWebBasicRegular/KlavikaWebBasicRegular.eot');
	src: url('../fonts/KlavikaWebBasicRegular/KlavikaWebBasicRegular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/KlavikaWebBasicRegular/KlavikaWebBasicRegular.woff2') format('woff2'),
         url('../fonts/KlavikaWebBasicRegular/KlavikaWebBasicRegular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	}

/* Klavika Web Basic Regular Italic */
	@font-face {
	font-family: 'KlavikaWebBasic';
	src: url('../fonts/KlavikaWebBasicRegularItalic/KlavikaWebBasicRegularItalic.eot');
	src: url('../fonts/KlavikaWebBasicRegularItalic/KlavikaWebBasicRegularItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/KlavikaWebBasicRegularItalic/KlavikaWebBasicRegularItalic.woff2') format('woff2'),
         url('../fonts/KlavikaWebBasicRegularItalic/KlavikaWebBasicRegularItalic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	}

/* Klavika Web Basic Medium */
	@font-face {
	font-family: 'KlavikaWebBasic';
	src: url('../fonts/KlavikaWebBasicMedium/KlavikaWebBasicMedium.eot');
	src: url('../fonts/KlavikaWebBasicMedium/KlavikaWebBasicMedium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/KlavikaWebBasicMedium/KlavikaWebBasicMedium.woff2') format('woff2'),
         url('../fonts/KlavikaWebBasicMedium/KlavikaWebBasicMedium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	}

/* Klavika Web Basic Medium Italic */
	@font-face {
	font-family: 'KlavikaWebBasic';
	src: url('../fonts/KlavikaWebBasicMediumItalic/KlavikaWebBasicMediumItalic.eot');
	src: url('../fonts/KlavikaWebBasicMediumItalic/KlavikaWebBasicMediumItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/KlavikaWebBasicMediumItalic/KlavikaWebBasicMediumItalic.woff2') format('woff2'),
         url('../fonts/KlavikaWebBasicMediumItalic/KlavikaWebBasicMediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	}

/* Klavika Web Basic Bold */
	@font-face {
	font-family: 'KlavikaWebBasic';
	src: url('../fonts/KlavikaWebBasicBold/KlavikaWebBasicBold.eot');
	src: url('../fonts/KlavikaWebBasicBold/KlavikaWebBasicBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/KlavikaWebBasicBold/KlavikaWebBasicBold.woff2') format('woff2'),
         url('../fonts/KlavikaWebBasicBold/KlavikaWebBasicBold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	}

/* Klavika Web Basic Bold Italic */
	@font-face {
	font-family: 'KlavikaWebBasic';
	src: url('../fonts/KlavikaWebBasicBoldItalic/KlavikaWebBasicBoldItalic.eot');
	src: url('../fonts/KlavikaWebBasicBoldItalic/KlavikaWebBasicBoldItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/KlavikaWebBasicBoldItalic/KlavikaWebBasicBoldItalic.woff2') format('woff2'),
         url('../fonts/KlavikaWebBasicBoldItalic/KlavikaWebBasicBoldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
	}