.answer-container {
    padding-top: 2rem;
}

.contact-confirm {
    padding-bottom: 2rem;
    font-size: 12px;
}

@keyframes bubble-flash {
    0%   {        
        opacity: 0.8;
    }
    20% {
        opacity: 0.5;
    }
    100%  {
        opacity: 1;
    }
  }

.notification {
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-top: 20px;
}

.bubble-container {
    padding-top: 24px;
    padding-left: 16px;
}
.bubble {
    color: #ffffff;
    align-self: flex-start;
    background-color: #00a0e3;
    font-weight: 500;
    font-size: 12px;
    opacity: 1;
    animation-name: bubble-flash;
    animation-duration: 1.0s;
    animation-iteration-count: 1;
    animation-delay: 1s;
    font-size: 16px;
    line-height: 1.4;
    margin: 1px 0;
    padding: 8px 17px 6px 13px;
    max-width: 380px;
    position: relative;
    border-radius: 0px 18px 18px 18px;
  }
  
.arrowdown {
    border: solid 10px transparent;
    position: absolute;
    margin: -1px 0 0 50px;
    opacity: 1;
    animation-name: bubble-flash;
    animation-duration: 1.0s;
    animation-iteration-count: 1;
    animation-delay: 1s;
}
  

.highlight-frame {
    margin-top: 12px;
}

.highlight-message {
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 12px;
}

.select {
    min-width: 25% !important;
}

details[open] > *:not(summary) {
    border: 1px lightgrey solid;
    padding: 1rem;
    border-radius: 4px;
    overflow-y: auto;
}

summary {
    cursor: pointer;
}
details {
    height: auto;
    overflow-y: clip;
    max-height: 1.5rem;
    transition: all 0.3s linear;
}
  
details[open] {
    max-height: 99rem;
    transition: all 1s linear;
}
.success-container {
    padding: 3rem;
}

.validation-error, .MuiFormHelperText-root.validation-error {
    color: #ee4035;
}

.broker-name {
    font-size: 12px;
    font-weight: 600;
}

.errormessage {
    color: ee4035;
    background: #ffdfdf;
    padding: 2rem;
    margin-bottom: 1rem;
}

.answer-heading a:hover {
    text-decoration: underline;
}

.answer-heading a {
    font-weight: 400;
    color: inherit;
    text-decoration: none;
}

/*
.answer-element-text-after a:hover {
    text-decoration: underline;
}

.answer-element-text-after a {
    font-weight: 400;
    color: inherit;
    text-decoration: none;
}*/

.drop-area {
    border: 2px dashed #ccc;
    border-radius: 20px;    
    width: 100%;
    color: #5d5d5d;
    padding: 20px;
}

/* Auf allen Unterelementen nicht auf Mouse-Events (Draggen) reagieren */
.drop-area * {
    pointer-events: none;
}

@keyframes sizewarning {
    0%   {
        background-color: #fff6f6;
        border-color: red;
    }
    50%  {
        background-color: #fff6f6;
        border-color: red;
    }
    100% {background-color: inherit;}
  }

@keyframes sizewarning-file {
    0%   {        
        color: white;
        background-color: red;
    }
    50%  {
        color: white;
        background-color: red;
    }
    100% {color: inherit; background-color: inherit;}
  }

.drop-area.sizewarning {
    animation-name: sizewarning;
    animation-duration: 1.0s;
}

.drop-area .filesize.sizewarning {
    animation-name: sizewarning-file;
    animation-duration: 1.0s;
}

.drop-area .filetype.sizewarning {
    animation-name: sizewarning-file;
    animation-duration: 1.0s;
}

/* Die Click-Events der Unterelemente müssen wir hier wieder anschalten */
.drop-area .chip *, .drop-area .MuiButtonBase-root * {
    pointer-events: auto;
    line-height: 2.5rem;    
    cursor: pointer;
}

.drop-area .chip * {
    max-width: 100%;
}

.drop-area.highlight {
    border-color: #0099cc;
    background-color: #ccc;    
}

.drop-area .chip {
    margin-bottom: 4px;
    margin-top: 4px;
    max-width: 100%;
}


.fileupload {
    display: none;
}

.file-upload-button {
    cursor: pointer;
}