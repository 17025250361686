
.noborders table, th, td {
    border: 0;
}

.header-container {
  /*margin-top: -8px;*/
  align-items: center;
}

img.logo {
  top: 0;
  width: 50%;
  max-height: 5rem;
  float: right;
  padding-top: 1rem;
  vertical-align: middle;
  /*transform: translate(0, 50%);*/
}

img.logo {
  max-height: 5rem;
  vertical-align: middle;
  height: 100%;
  width: 50%;
  float: left;
  /*
  padding-top: 1rem;
  */
}

img.logo.logo-rd {
  width: 180px;
  height: 6.5rem;
}

img.logo.logo-cl-devk {
  width: 120px;
}

img.logo.logo-rl {
  /*
  padding-left: 1rem;
  padding-right: 1rem;
  */
  margin-left:1rem;
  width: 180px;
  padding-bottom: 1rem;
  /*
  background-color: #ffffff;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
*/
}

.header {
  display: block;
  min-height: 6.5rem;
  margin-bottom: 1.5rem;
}

.stretch-100 {
  width: 100%;
}

.radio {
  min-width: 25%;
}

.select {
  min-width: 25%;
}

.multicheckitem {
  width: 100%;
}

.small {
  font-size: 12px;
}

.padding-1 {
  padding: 1rem;
}

.padding-2 {
  padding: 2rem;
}

.padding-3 {
  padding: 3rem;
}

.padding-top-1 {
  padding-top: 1rem;
}

.padding-top-2 {
  padding-top: 2rem;
}

.padding-top-3 {
  padding-top: 3rem;
}

.padding-bottom-1 {
  padding-bottom: 1rem;
}

.padding-bottom-2 {
  padding-bottom: 2rem;
}

.padding-bottom-3 {
  padding-bottom: 3rem;
}

.padding-bottom-10 {
  padding-bottom: 10rem;
}

.padding-x-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.padding-x-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.padding-x-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.padding-y-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.padding-y-1 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.padding-y-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.bold {
  font-weight: 700;
}

.answer-element .share-menu {
  opacity: 0;
  transition: 0.2s ease-in-out;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  embed.pdf-viewer, object.pdf-viewer {
    display: none;
  }
}

embed.pdf-viewer, object.pdf-viewer {
  background-color: #ffffff;
  height: 100vh;
  min-height: 600px;
}

.answer-element:hover .share-menu {
  opacity: 1;
  transition: 0.2s ease-in-out;
}

.answer-element .share-menu svg {
  margin-top: 4px;
}

.MuiButton-root.button-margin-right{
  margin-right: 1rem;
}

.vermittler-ansprechpartner {
  padding-right:20px;
}

.vermittler-container {
  display: flex;
  align-items: center;
  padding-top: 20px;
  float: right;
}

.vermittler-title {
  font-size: 14px;
  text-align: right;
  line-height: 20px;
}

.vermittler-telefon {
  font-size: 14px;
  text-align: right;
  line-height: 20px;
}

.vermittler-telefon a {
  text-decoration: none;
  color:inherit;
}
.vermittler-telefon a:hover {
  text-decoration: underline;
}

.vermittler-telefon svg {
  height: 18px;
  width: 18px;
  margin-right: 8px;
  margin: 0;
  margin-right: 8px;
  margin-bottom: -5px;
}



.vermittler-email {
  font-size: 14px;
  text-align: right;
  line-height: 20px;
}

.vermittler-email a {
  text-decoration: none;
  color:inherit;
}
.vermittler-email a:hover {
  text-decoration: underline;
}

.vermittler-email svg {
  height: 18px;
  width: 18px;
  margin-right: 8px;
  margin: 0;
  margin-right: 8px;
  margin-bottom: -5px;
}


.vermittler-name {
  font-weight: 600;
  text-align: right;
}

.MuiFormControl-root.MuiTextField-root {
  min-width: 340px;
}

@media only screen and (max-width: 900px) {
  
  .MuiFormControl-root.MuiTextField-root {
    min-width: initial;
  }
  
  .radio, .select, .multicheckitem {
    width: 100%;
  }

  .MuiButton-root.button-margin-right{
    margin-right: initial;
    margin-bottom: 1rem;
  }

  .padding-1 {
    padding: 0.1rem;
  }
  .padding-2 {
    padding: 0.25rem;
  }
  .padding-3 {
    padding: 0.5rem;
  }
  .padding-x-1 {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }
  .padding-x-2 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .padding-x-3 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .padding-y-1 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }
    .padding-y-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .padding-y-3 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .padding-top-1 {
    padding-top: 0.1rem;
  }  
  .padding-top-2 {
    padding-top: 0.25rem;
  }  
  .padding-top-3 {
    padding-top: 0.5rem;
  }  
  .padding-bottom-1 {
    padding-bottom: 0.1rem;
  }
  .padding-bottom-2 {
    padding-bottom: 0.25rem;
  }
  .padding-bottom-3 {
    padding-bottom: 0.5rem;
  }
  .MuiButton-root {
    width: 100%;
  }  
  .MuiTextField-root {
    width: 100%;
  }

}


.vertical-align-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.buttonrow {
  padding-bottom: 1rem;
  padding-top: 2rem;
  text-align: right
}

.content-rl_leg {
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  display: block;
  margin-top: 1rem;
  margin-bottom: 4rem;
  background-color: #ffffff;
  box-shadow: 0px 3px 5px 2px rgba(100,100,100,0.3);
}

.content-wrapper {
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  /** Höhe des Footers */
  padding-bottom: 155px;
  display: block;
  position: relative;
  /*background-color: #ffffff;*/
  /*box-shadow: 0 3px 5px 2px rgb(100 100 100 / 30%);*/
}
@media only screen and (max-width: 1200px) {
  .content-wrapper {
    padding-bottom: 450px;
  }
}

.footer {
  /*background-color: #006ab3;*/
  color: #ffffff;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
  padding-bottom: 12px;
  margin: 0px;
  margin-bottom: -12px;
  padding-top: 1rem;
  width: 100%;
  position: absolute;
  text-align: center;
}
.footer-content {
  height: 4rem;
  background-color: #bdccd0;
  color: #8e8e8e;
  padding-bottom: 1rem;
  text-align: center;
  padding-top: 1.5rem;
  font-size: 0.7rem;
}

.footer a {
  color: #ffffff;
  text-decoration: none;
}

.footer a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.btn {
  border-radius: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.btn-primary {
  color: #fff;
  background-color: #0069b4;
  border-color: #0069b4;
}

.answer-heading {
  padding: 1rem;
  margin-bottom: 1rem;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.fade-in {
  opacity: 1;
  animation: fadein 0.3s ease-in-out;
  will-change: transform;
}

.answer-element {
  opacity: 1;
  animation: fadein 0.3s ease-in-out;
  will-change: transform;
}

.answer-element.unmounting {
  opacity: 0.0;
  transition: ease-in-out 0.3s;    
}

.wizard-step.unmounting {
  opacity: 0.05;
  transition: ease-in-out 0.3s;
}

.wizard-step {
  opacity: 1;
  animation: fadein 0.3s ease-in-out;
}

@keyframes fadein {
  0% {
    opacity: 0.05;
  }
  100% {
    opacity: 1;
  }
}

.link-button {
  color: #ffffff;
  display: inline-block;
  border: 0;
  height: 48px;
  padding: 13px 30px 0 30px;
  background: #0069b4;
  box-shadow: 0px 3px 5px 2px rgba(100,100,100,0.3);
  border-radius: 0;
  text-decoration: none;
  cursor: pointer;
}



@media only screen and (max-width: 900px) {
  .link-button {
    text-align: center;  
    width: 100%;
  }
}

.linked-app {
  /*height: 100%;*/
  /*width: 100%;*/
  min-height: 100%;
  position: relative;
}


.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: spin 1.8s infinite ease-in-out;
  animation: spin 1.8s infinite ease-in-out;
}
.spinner {
  color: #0069b4;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.spinner:before,
.spinner:after {
  content: '';
  position: absolute;
  top: 0;
}
.spinner:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner:after {
  left: 3.5em;
}
@-webkit-keyframes spin {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes spin {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@media print {
  html, body {
    -webkit-print-color-adjust: exact;
  }
}